
.pdp-quantity
    display flex
    justify-content center
    height 48px
    max-width 188px
    padding 4px
    width 100%
    font-size 16px
    font-weight 500
    line-height 18.4px
    border 1px solid rgb(0, 0, 0)
    border-radius 8px

.quantity-button
    align-items center
    display flex
    height 40px
    justify-content center
    margin 0
    overflow visible
    padding 0
    width 45px
    font-size 14px
    font-weight 600
    letter-spacing -0.24px
    line-height 18px
    text-align center
    color rgb(0, 0, 0)
    background-color rgba(0, 0, 0, 0)
    border 0
    cursor not-allowed
    transition all 0.15s ease
    appearance button
    text-rendering auto
    border-radius 32px
    overflow-wrap break-word
    padding 0
    &.disabled
        opacity 0.5

.quantity-input
    flex auto
    border none
    font-family: "SF Pro Text";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25012rem;
    padding: 10px 8px;
    width: 3.5rem;
    border: none;
    color: #000;
    text-align: center;
